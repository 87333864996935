<template>
  <div class="w-screen h-screen flex flex-col">
    <div class="mx-0 my-0 flex flex-row font-sm text-sm h-full w-full">
      <div class="flex flex-col mx-0 my-0 space-y-4 w-14 md:w-48 justify-between bg-gray-700">
        <div class="flex flex-col px-4 py-1 mx-0 pt-5 space-y-2">
          <div class="px-2 font-semibold text-2xl text-white text-shadow-md hidden lg:block">
            VDIRECTOR
          </div>
          <router-link
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/dashboard"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Dashboard</div>
              <div class="text-gray-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="home w-4 h-4"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_admin"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/users"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Users</div>
              <div class="text-blue-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="users w-4 h-4"
                >
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_admin"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/eventlog"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Eventlog</div>
              <div class="text-blue-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="clipboard-list w-4 h-4"
                >
                  <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                  <path
                    fill-rule="evenodd"
                    d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_admin"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal hidden md:block"
            to="/app/logstream"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Logstream</div>
              <div class="text-blue-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="collection w-4 h-4"
                >
                  <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                </svg>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="USER_OBJECT.is_admin"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/deviceconfigs"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Dev.-Configs</div>
              <div class="text-blue-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="finger-print w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_tech"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/deviceerrors"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Dev.-Errors</div>
              <div class="text-red-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_tech"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/temperatures"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Temperatures</div>
              <div class="text-red-400">
                <!-- <svg viewBox="0 0 20 20" fill="currentColor" class="fire w-4 h-4">
                  <path
                    fill-rule="evenodd"
                    d="M11.757 2.034a1 1 0 01.638.519c.483.967.844 1.554 1.207 2.03.368.482.756.876 1.348 1.467A6.985 6.985 0 0117 11a7.002 7.002 0 01-14 0c0-1.79.684-3.583 2.05-4.95a1 1 0 011.707.707c0 1.12.07 1.973.398 2.654.18.374.461.74.945 1.067.116-1.061.328-2.354.614-3.58.225-.966.505-1.93.839-2.734.167-.403.356-.785.57-1.116.208-.322.476-.649.822-.88a1 1 0 01.812-.134zm.364 13.087A2.998 2.998 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879.586.585.879 1.353.879 2.121s-.293 1.536-.879 2.121z"
                    clip-rule="evenodd"
                  />
                </svg>-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_tech"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/cameras"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Cameras</div>
              <div class="text-red-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="video-camera w-4 h-4"
                >
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_sale"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/playoutscreen"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Playout Screen</div>
              <div class="text-green-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="document-report w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_sale"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal"
            to="/app/playouttrain"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Playout Train</div>
              <div class="text-green-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="document-report w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="USER_OBJECT.is_sale"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 hover:bg-gray-50 hover:text-blue-800 font-normal hidden md:block"
            to="/app/stationtap"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Station Tap</div>
              <div class="text-green-400">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="film w-4 h-4"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm3 2h6v4H7V5zm8 8v2h1v-2h-1zm-2-2H7v4h6v-4zm2 0h1V9h-1v2zm1-4V5h-1v2h1zM5 5v2H4V5h1zm0 4H4v2h1V9zm-1 4h1v2H4v-2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <div
            v-on:click.prevent="LOGOUT()"
            class="flex-1 rounded-sm px-1 py-1 text-gray-50 font-normal cursor-pointer"
          >
            <div class="flex items-center">
              <div class="flex-1 hidden md:block">Logout</div>
              <div class="text-purple-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="film w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style scoped>
.router-link-active,
.router-link-exact-active {
  @apply text-blue-800;
  @apply bg-gray-50;
  @apply font-normal;
}
.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.text-shadow-md {
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.text-shadow-lg {
  text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
}

.text-shadow-none {
  text-shadow: none;
}
</style>

<script>
import axios from "axios";
export default {
  name: "AppRoot",
  components: {},
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {},
  data() {
    return {
      USER_OBJECT: {},
      AUTH_HEADER: "",
    };
  },
  computed: {
    AUTH_LEVEL: function () {
      let { USER_OBJECT } = this;
      let level = "";
      if (USER_OBJECT.is_admin) {
        level = "Admin";
      } else if (USER_OBJECT.is_tech) {
        level = "Tech";
      } else if (USER_OBJECT.is_sale) {
        level = "Sale";
      }
      return level;
    },
  },
  methods: {
    LOGOUT: function () {
      localStorage.removeItem("USER_OBJECT");
      localStorage.removeItem("AUTH_HEADER");
      this.$store.commit("UPDATE_USER_OBJECT", null);
      this.$store.commit("UPDATE_AUTH_HEADER", null);
      this.$router.push("/");
    },
  },
};
</script>
